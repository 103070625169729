import { createRoot } from 'react-dom/client';
import { StrictMode, lazy, Suspense } from 'react';
import { kcContext } from './KcApp/kcContext';

const KcApp = lazy(() => import('./KcApp'));

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <Suspense>{kcContext !== undefined && <KcApp kcContext={kcContext} />}</Suspense>
    </StrictMode>
);
